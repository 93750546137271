<template>
  <b-modal id="modal-subscription" size="lg" :title="editUuid !== null
    ? 'Detail Berlangganan'
    : 'Tambah Berlangganan'">
    <div>
      <div>
        <label for="v-nama">Nama Berlangganan <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-nama" v-model="formPayload.renewal_name" type="text" placeholder="Nama Berlangganan"
            class="custom__input" />
        </b-form-group>
      </div>
      <div>
        <label for="v-description">Deskripsi Berlangganan
          <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-description" v-model="formPayload.renewal_description" type="text" placeholder="Deskripsi"
            class="custom__input" />
        </b-form-group>
      </div>
      <div>
        <label for="v-months">Masa Berlangganan (Bulan)
          <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-months" v-model="formPayload.renewal_duration_months" type="text"
            placeholder="Masa Berlangganan" class="custom__input" />
        </b-form-group>
      </div>
      <div>
        <b-form-group label-for="v-harga">
          <label for="">Harga <span class="text-danger">*</span></label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text class="w-100">
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money id="v-harga" v-model="formPayload.price" v-bind="money" placeholder="Harga"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8 w-100" />
            </b-input-group-prepend>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-form-group label-for="v-harga-jual">
          <label for="">Harga Jual <span class="text-danger">*</span></label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text class="w-100">
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money id="v-harga-jual" v-model="formPayload.sell_price" v-bind="money" placeholder="Harga"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8 w-100" />
            </b-input-group-prepend>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <label for="v-discount">Diskon (%)
          <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-discount" v-model="formPayload.discount_percent" type="text" placeholder="Diskon"
            class="custom__input" />
        </b-form-group>
      </div>
    </div>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="updateItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    getData: {
      type: Function,
    },
    result: {
      type: Object,
    },
    // getDetail: {
    //   type: Function,
    // },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    editId: {
      type: "",
    },
    cleanUpForm: {
      type: Function,
    },
  },
  watch: {
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  data() {
    return {
      editUuid: null,
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      formPayload: {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      },
      searchQuery: "",
      countryList: [],
      optionsToko: [],
    };
  },
  mounted() { },
  methods: {
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide("modal-subscription");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

label {
  font-size: 14px;
  font-weight: 600;
  color: #2b324f;
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
  color: #2b324f;
  font-weight: 600;
  font-size: 14px;
}
</style>
