<template>
  <div class="section">
    <TableSubscription :get-data="getData" :result="result" :detail-result="detailResult" :edit-item="editItem"
      :get-filter="getFilter" @filterBase="getFilter" />
    <ModalSubscription :get-data="getData" :result="result" :detail-result="detailResult" :form-data="formPayload"
      :edit-item="editItem" :update-item="updateItem" :editId="editId" :cleanUpForm="cleanUpForm"
      @getPayload="getPayload" />
  </div>
</template>

<script>
import TableSubscription from "@/components/Subscription/TableSubscription.vue";
import ModalSubscription from "@/components/Subscription/Modal/ModalSubscription.vue";
import HeaderPage from "@/components/HeaderPage.vue";

import { successNotification, errorNotification } from "@/auth/utils";

export default {
  title() {
    return "Subscription";
  },
  components: {
    HeaderPage,
    TableSubscription,
    ModalSubscription,
  },
  data() {
    return {
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
      formPayload: {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      },
      editId: null,
      currentPage: 1,
      isLoading: true,
      result: {},
      detailResult: {},
      validations: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getFilter(value) {
      this.$router.replace({ query: "" });
      this.filter = {
        ...value,
      };
      this.getData();
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      try {
        this.$store
          .dispatch("subscription/getData", { uuid: "", params: queryParams })
          .then((result) => {
            this.isLoading = false;
            this.result = result.data.data;
            this.currentPage = result.data.data.current_page;
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      } catch (error) {

      }
    },
    getPayload(value) {
      this.formPayload = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      };
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    editItem(id) {
      this.cleanUpForm();
      this.editId = id;
      this.$store
        .dispatch("subscription/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.isLoading = false;
          const data = result.data.data;

          this.formPayload = {
            renewal_name: data.renewal_name,
            renewal_description: data.renewal_description,
            renewal_duration_months: data.renewal_duration_months,
            price: data.price,
            sell_price: data.sell_price,
            discount_percent: data.discount_percent,
            active_discount_period: data.active_discount_period,
          };

          this.$store.commit("subscription/setFormPayload", {
            renewal_name: data.renewal_name,
            renewal_description: data.renewal_description,
            renewal_duration_months: data.renewal_duration_months,
            price: data.price,
            sell_price: data.sell_price,
            discount_percent: data.discount_percent,
            active_discount_period: data.active_discount_period,
          });
          this.$bvModal.show("modal-subscription");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("subscription/postData", {
          params: `/${this.editId}`,
          payload: form,
        })
        .then(async () => {
          await this.getData();
          successNotification(
            this,
            "Success",
            "Subscription berhasil di ubah!"
          );
          this.cleanUpForm();
          this.isLoading = false;
          this.$bvModal.hide("modal-subscription");
          // window.location.href = '/master/users'
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
