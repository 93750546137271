<template>
  <div class="">

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.renewal_name || '-' }}</td>
              <td>{{ item.renewal_duration_months }} Bulan</td>
              <td>{{ item.price | formatAmount }}</td>
              <td>{{ item.sell_price | formatAmount }}</td>
              <td>{{ item.discount_percent }} %</td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button variant="primary" @click="editItem(item.id)">
                    Lihat Detail
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import FilterButton from "@/components/BaseFilter.vue";
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    FilterButton,
    BCardCode,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    getFilter: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    result: {
      type: Object,
    },
    getDetail: {
      type: Function,
    },
    detailResult: {
      type: Object,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Berlangganan",
          field: "renewal_name",
        },
        {
          label: "Durasi Berlangganan",
          field: "renewal_duration_months",
        },
        {
          label: "Harga",
          field: "price",
        },
        {
          label: "Harga Jual",
          field: "sell_price",
        },
        {
          label: "Diskon",
          field: "discount_percent",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "desc",
        per_page: 10,
      },
      isLoadingExport: false,
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBase", value);
      },
      deep: true,
    },
  },
  mounted() {
    console.log(this.result.length);
  },
  methods: {
    // goToDetail(uuid) {
    //   this.getDetail(uuid);
    //   this.$bvModal.show("modal-cabang");
    // },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.section__subscription {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
